#header-bar {
  /* height: 64px; */
  padding: 0px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  /* box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12); */
}

.desktop #header-bar {
  padding: 35px 24px;
}

.site-logo-cntr {
  display: flex;
  align-items: center;
}

.site-logo-cntr img {
  /* max-height: 40px; */
}

.menu {
  cursor: pointer;
}

.site-title {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-left: 8px;
}

#header-bar a {
  color: #2d2d2d;
  cursor: pointer;
  display: flex;
}
