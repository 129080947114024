.eng-screen-bg {
    height: 94%;
    margin: 3%;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
}

.header {
    height: 15%;
    padding: 3% 7%;
    display: flex;
    box-shadow: 0px 4px 6px -3px #9c9c9c;
    margin-bottom: 4%;
}

.header_info {
    display: flex;
    align-items: baseline;
    width: 100%;
    margin-right: 100%;
}

.engList-scrollable-darkgray::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.engList-scrollable-darkgray::-webkit-scrollbar-thumb {
    background-color: #989696;
    border-radius: 6px;
}

.engList-scrollable-darkgray::-webkit-scrollbar-track {
    box-shadow: none;
    margin: 6px;
}

.open-eng-list {
    background-color: #FFBC36 !important;
    color: white !important;
}