@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;700;800&family=Merriweather:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap);
*{ margin: 0px; padding: 0px; box-sizing: border-box; }
.e-link:hover {
 background: transparent;
}

.external-links { line-height: 1.65; color: #505050; font-size: 15px;}
.external-links p{ margin: 0px 0px 12px; }
.external-links li{margin-left: 16px;}
.highlighted-patch{margin-top: 16px}

.f15_b{font-weight: 700; color: #000}
.input-cntr {
}

.errorMsg {
  /* height: 1.375em; */
}

.auth-form-cntr {
  margin: auto;
  max-width: 534px;
  padding: 0px 40px;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,.2); */
}

.desktop {
  max-width: 1366px;
  margin: 0 auto;
}

.desktop,
.desktop .auth-cntr {
  height: 100%;
}

.desktop .login-artifact {
  background: #fff9e5 0% 0% no-repeat padding-box;
}

.desktop .rl-btns {
  display: flex;
}

.desktop .rl-btns .RegistrationBtn,
.desktop .rl-btns .loginBtn {
  margin-top: 0 !important;
}

.desktop .rl-btns .login-link-cntr {
  align-items: flex-start !important;
  margin-left: 25px !important;
  justify-content: center;
}

.mobile .login-artifact img {
  height: 313px;
}

.errorMsg {
  color: #e6111b;
  font-size: 0.875rem;
  padding: 5px 0;
}

#header-bar {
  /* height: 64px; */
  padding: 0px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  /* box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12); */
}

.desktop #header-bar {
  padding: 35px 24px;
}

.site-logo-cntr {
  display: flex;
  align-items: center;
}

.site-logo-cntr img {
  /* max-height: 40px; */
}

.menu {
  cursor: pointer;
}

.site-title {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-left: 8px;
}

#header-bar a {
  color: #2d2d2d;
  cursor: pointer;
  display: flex;
}

.flexBox {
    display: flex;
}

.successMsg {
    color: #127627;
    /* font-size: 0.875rem; */
    padding: 5px 0;
}

.flex-center {
    align-items: center;
    justify-content: center;
}

.ml-3 {
    margin-left: 3%;
}
.f14 {
  font: normal normal normal 14px/22px Merriweather Sans;
}

.f15 {
  font: normal normal normal 15px/25px Merriweather Sans;
}

.f15_b {
  font: normal normal bold 15px/25px Merriweather Sans;
}

.desktop .highlighted-patch {
  padding: 30px;
}

.mobile .highlighted-patch {
  padding: 20px;
}

.highlighted-patch {
  margin-top: 30px;
  background-color: #f2f2f2;
  margin-bottom: 60px;
}

p {
  font: normal normal medium 12px/18px Metropolis;
  /* font-weight: 500; */
  /* margin-bottom: 15px; */
  color: #1d4352;
}

li {
  /* font: normal normal normal 14px/20px Merriweather Sans; */
  margin-bottom: 10px;
  /* color: #505050; */
  margin-left: 1rem;
  font: normal normal medium 12px/18px Metropolis;
  /* font-weight: 500; */
  /* margin-bottom: 15px; */
  color: #1d4352;;
}

.external-links a {
  color: black;
}

.live-session-button {
  color: inherit;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  float: right;
}

.live-session-dot {
  font-size: 40px;
  margin: 0px 5px;
  margin-bottom: 8px;
  color: mediumspringgreen;
}

.align-center {
  display: flex;
  align-items: center;
}

.bookcover {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
  }
  .bookcover-bordered {
    border-radius: 6px;
    border: 1px solid #cdcdcd;
  }
  .loading-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    color: #ffffff;
  }
  
  ._book-cntr {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    flex-grow: 1;
    padding-top: 48px;
  }
  
  ._book_meta_cntr {
    height: 100%;
    background-color: #333333;
  }
  
  .book-meta-cntr .page-cntr {
    background-color: #333333;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  li.page-cntr{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .book-reader-menu {
    position: absolute;
    height: 50px;
    width: 100px;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #fff;
  }
  
  .page-numbers {
    position: absolute;
    height: 50px;
    width: 100px;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #aaaaaa;
  }
  
  #current-page {
    font-size: 1.8rem;
  }
  
  #number-of-pages {
    font-size: 1.2rem;
  }
  
  .react-pdf__Page__canvas{margin: auto;}

  .glide__arrow{
    border: none !important;
    padding: 9px 2px;
    border-radius: 0px;
  }
  .react-pdf__Page{display: flex; justify-content: center;}
  .glide__track{background-color: #333;}
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .glide__arrow--left{
      left: 0px;
     }
     
     .glide__arrow--right{
       right: 0px;
     }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    ._book-cntr {
      padding-top: 0px;
    }
  }
  
.otp-screen-bg {
    padding: 4%;
    border-radius: 24px;
    box-shadow: 0px 1px 4px 0px;
}
.answer-button {
    padding: 10px !important;
    box-shadow: rgb(176 176 176 / 70%) 0px 0px 6px;
    margin: 10px !important;
    cursor: pointer !important;
    border: 1px solid lightgrey !important;
}
.flex-wrap {
    display: grid;
    /* flex-wrap: wrap; */
    grid-template-columns: auto auto;
}

.w-70 {
    width: 70%;
}

.flex-basis-44 {
    flex-basis: 44%;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
    width: 80%;
    height: 172px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0000001F;
    border-radius: 4px;
    opacity: 1;
    
}
.buttons{
    display: block;
    white-space: nowrap;
    width: 6em;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 70px !important;
    
}

.engItems-body-slider {
    width: 100%;
    height: 80%;
    margin-top: 20px;
    padding: 0px 10px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-wrap {
    display: grid;
    /* flex-wrap: wrap; */
    grid-template-columns: auto auto;
}

.w-70 {
    width: 70%;
}

.flex-basis-44 {
    flex-basis: 44%;
}

.eng-screen-bg {
    height: 94%;
    margin: 3%;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
}

.header {
    height: 15%;
    padding: 3% 7%;
    display: flex;
    box-shadow: 0px 4px 6px -3px #9c9c9c;
    margin-bottom: 4%;
}

.header_info {
    display: flex;
    align-items: baseline;
    width: 100%;
    margin-right: 100%;
}

.engList-scrollable-darkgray::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.engList-scrollable-darkgray::-webkit-scrollbar-thumb {
    background-color: #989696;
    border-radius: 6px;
}

.engList-scrollable-darkgray::-webkit-scrollbar-track {
    box-shadow: none;
    margin: 6px;
}

.open-eng-list {
    background-color: #FFBC36 !important;
    color: white !important;
}
html, body, #root{height:100%}
* {
    font-family: "Merriweather Sans", sans-serif;
}
.-hf {
    font-family: "Merriweather", serif;
}

.footer {
    padding: 30px;
    /* position: absolute; */
    /* bottom: 0; */
    /* width: 100%; */
}

.line-clamp {
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.line-clamp-desktop {
    -webkit-line-clamp: 4;
}

.line-clamp-mobile {
    -webkit-line-clamp: 6;
}

.e-link:hover {
    background: transparent;
}

.external-links p {
    line-height: 1.7;
}

