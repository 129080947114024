.f14 {
  font: normal normal normal 14px/22px Merriweather Sans;
}

.f15 {
  font: normal normal normal 15px/25px Merriweather Sans;
}

.f15_b {
  font: normal normal bold 15px/25px Merriweather Sans;
}

.desktop .highlighted-patch {
  padding: 30px;
}

.mobile .highlighted-patch {
  padding: 20px;
}

.highlighted-patch {
  margin-top: 30px;
  background-color: #f2f2f2;
  margin-bottom: 60px;
}

p {
  font: normal normal medium 12px/18px Metropolis;
  /* font-weight: 500; */
  /* margin-bottom: 15px; */
  color: #1d4352;
}

li {
  /* font: normal normal normal 14px/20px Merriweather Sans; */
  margin-bottom: 10px;
  /* color: #505050; */
  margin-left: 1rem;
  font: normal normal medium 12px/18px Metropolis;
  /* font-weight: 500; */
  /* margin-bottom: 15px; */
  color: #1d4352;;
}

.external-links a {
  color: black;
}

.live-session-button {
  color: inherit;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  float: right;
}

.live-session-dot {
  font-size: 40px;
  margin: 0px 5px;
  margin-bottom: 8px;
  color: mediumspringgreen;
}

.align-center {
  display: flex;
  align-items: center;
}
